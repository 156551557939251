import React from 'react';

const NumberCall = () => {
  return (
    <div className="number-call">
      <a href="tel:09194508707">شاه نظری 09194508707</a>
    </div>
  );
};

export default NumberCall;
