import React, { useState, useEffect } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createProperty } from '../redux/actions/propertyAction';
import Loader from '../components/Loader';
import numberSeparator from 'number-separator';
import axios from 'axios';
import { PROPERTY_CREATE_RESET } from '../redux/constants/propertConstant';
const AddProperty = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const createPropertyState = useSelector((state) => state.createProperty);
  const { error, loading, success } = createPropertyState;
  const [name, setName] = useState();
  const [code, setCode] = useState();
  const [bed, setBed] = useState();
  const [meter, setMeter] = useState();
  const [year, setYear] = useState();
  const [floor, setFloor] = useState();
  const [nama, setNama] = useState();
  const [parking, setParking] = useState();
  const [description, setDiscription] = useState();
  const [activeRent, setActiveRent] = useState(false);
  const [lux, setLux] = useState(false);
  const [rentPish, setRentPish] = useState();
  const [rentMonth, setRentMonth] = useState();
  const [pricePerMeter, setPricePerMeter] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [uploading, setUploading] = useState();
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [image6, setImage6] = useState();
  const [image7, setImage7] = useState();
  const [image8, setImage8] = useState();
  const [image9, setImage9] = useState();
  const [image10, setImage10] = useState();
  const [image11, setImage11] = useState();
  const [image12, setImage12] = useState();
  const [image13, setImage13] = useState();
  const [image14, setImage14] = useState();
  const [image15, setImage15] = useState();
  const [video, setVideo] = useState();

  const [anten, setAnten] = useState(false);
  const [asansor, setAsansor] = useState(false);
  const [remoteDoor, setRemoteDoor] = useState(false);
  const [cooler, setCooler] = useState(false);
  const [packagee, setPackagee] = useState(false);
  const [abgarm, setAbgarm] = useState(false);
  const [spilet, setSpilet] = useState(false);
  const [anbari, setAnbari] = useState(false);
  const [balkon, setBalkon] = useState(false);
  const [iphone, setIphone] = useState(false);
  const [cattegory, setCattegory] = useState([]);

  const [sakhtosaaz, setSakhtosaaz] = useState(false);
  const [sellEdari, setSellEdari] = useState(false);
  const [rentHome, setRentHome] = useState(false);
  const [rentEdari, setRentEdari] = useState(false);
  const [shortRent, setShortRent] = useState(false);
  const [sellHome, setSellHome] = useState(false);
  const [village, setVillage] = useState(false);
  const [sellGarden, setSellGarden] = useState(false);
  const [exChanage, setExChanage] = useState(false);

  const [sakhtosaazMosharekat, setSakhtosaazMosharekat] = useState(false);
  const [preSell, setPreSell] = useState(false);
  const [others, setOthers] = useState(false);
  const [office, setOffice] = useState(false);
  const [shop, setShop] = useState(false);
  const [apartment, setApartment] = useState(false);
  const [vilaaii, setVilaaii] = useState(false);
  const [land, setLand] = useState(false);
  const [vilaavBaagh, setVilaavBaagh] = useState(false);
  const [sule, setSule] = useState(false);
  const [baaghVilla, setBaaghVilla] = useState(false);
  const [sanatiKeshavarzi, setSanatiKeshavarzi] = useState(false);
  const [hektari, setHektari] = useState(false);
  const propertyData = {
    name,
    code,
    meter,
    year,
    floor,
    nama,
    bed,
    lux,
    parking,
    description,
    rent: activeRent,
    rentPish,
    rentMonth,
    pricePerMeter,
    totalPrice,
    image,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    video,
    anten,
    asansor,
    packagee,
    spilet,
    abgarm,
    remoteDoor,
    cooler,
    anbari,
    balkon,
    iphone,
    category: cattegory,
  };
  const changeCattegory = (value) => {
    if (!cattegory.includes(value)) {
      setCattegory([...cattegory, value]);
    } else {
      cattegory.splice(cattegory.indexOf(value), 1);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProperty(propertyData));
  };
  useEffect(() => {
    if (!userInfo) {
      history.push('/');
    }
    if (success) {
      dispatch({ type: PROPERTY_CREATE_RESET });
      history.push('/');
    }
  }, [history, userInfo, success, dispatch]);
  const uploadHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload', formData, config);
      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler2 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image2', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload2', formData, config);
      setImage2(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler3 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image3', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload3', formData, config);
      setImage3(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler4 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image4', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload4', formData, config);
      setImage4(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler5 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image5', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload5', formData, config);
      setImage5(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler6 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image6', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload6', formData, config);
      setImage6(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler7 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image7', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload7', formData, config);
      setImage7(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler8 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image8', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload8', formData, config);
      setImage8(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler9 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image9', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload9', formData, config);
      setImage9(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler10 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image10', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload10', formData, config);
      setImage10(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler11 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image11', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload11', formData, config);
      setImage11(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler12 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image12', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload12', formData, config);
      setImage12(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler13 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image13', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload13', formData, config);
      setImage13(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler14 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image14', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload14', formData, config);
      setImage14(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const uploadHandler15 = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image15', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload15', formData, config);
      setImage15(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  const videoHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('video', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/video', formData, config);
      setVideo(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };
  return (
    <Container className="mb-5">
      <h3 className="my-4 my-md-5">اضافه کردن ملک</h3>
      <Alert variant="danger" className="mb-4">
        تمام ارقام وارد شده در هر فیلدی باید به تومن باشد و تمام توضیحات و متون
        حتما به فارسی نوشته شود .
      </Alert>
      <div>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>اسم ملک</Form.Label>
            <Form.Control
              type="text"
              placeholder="نام ملک را وارد کنید"
              style={{ boxShadow: 'none' }}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="code" className="pt-3">
            <Form.Label>کد ملک</Form.Label>
            <Form.Control
              type="text"
              placeholder="کد ملک را وارد کنید"
              style={{ boxShadow: 'none' }}
              onChange={(e) => setCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="meter" className="pt-3">
            <Form.Label>متراژ ملک</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="متراژ ملک را وارد کنید (فقط عدد)"
              style={{ boxShadow: 'none', direction: 'rtl' }}
              onChange={(e) => {
                setMeter(e.target.value);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="year" className="pt-3">
            <Form.Label>سال ساخت</Form.Label>
            <Form.Label className="ps-2 text-muted">
              (در صورت نداشتن مقدار فیلد را خالی بزارید)
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="سال ساخت ملک را وارد کنید (فقط عدد)"
              style={{ boxShadow: 'none', direction: 'rtl' }}
              onChange={(e) => setYear(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="floor" className="pt-3">
            <Form.Label>طبقه</Form.Label>
            <Form.Label className="ps-2 text-muted">
              (در صورت نداشتن مقدار فیلد را خالی بزارید)
            </Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="طبقه که ملک در ان قرار دارد را وارد کنید (فقط عدد)"
              style={{ boxShadow: 'none', direction: 'rtl' }}
              onChange={(e) => setFloor(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="nama" className="pt-3">
            <Form.Label>نما</Form.Label>
            <Form.Control
              type="text"
              style={{ boxShadow: 'none' }}
              onChange={(e) => setNama(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="parking" className="pt-3">
            <Form.Label>پارکینگ</Form.Label>
            <Form.Control
              type="text"
              style={{ boxShadow: 'none' }}
              onChange={(e) => setParking(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="description" className="pt-3">
            <Form.Label>توضیحات ملک</Form.Label>
            <Form.Control
              as="textarea"
              rows={7}
              style={{ boxShadow: 'none' }}
              onChange={(e) => setDiscription(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <h4 className="mt-4 mb-3">
            خواب (اختیاری اگه میخواهید اطلاعات در صفحه نمایش داده شود برای مشتری
            مقدار مناسب را وارد کنید در غیر این صورت انتخاب نکنید)
          </h4>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Form.Group className="d-flex my-4">
              <Form.Label className="pe-2">یک خواب </Form.Label>
              <Form.Check
                onChange={() => setBed(1)}
                checked={bed === 1 && true}
              />
            </Form.Group>
            <Form.Group className="d-flex my-4">
              <Form.Label className="pe-2">دو خواب</Form.Label>
              <Form.Check
                onChange={() => setBed(2)}
                checked={bed === 2 && true}
              />
            </Form.Group>
            <Form.Group className="d-flex my-4">
              <Form.Label className="pe-2"> سایر</Form.Label>
              <Form.Check
                onChange={() => setBed(0)}
                checked={bed === 0 && true}
              />
            </Form.Group>
          </Col>
          <h4 className="mt-4 mb-3">
            لوکس و استخر دار (برای ویلا یا هر ملکی تا در دسته لوکس قرار بگیرد.)
          </h4>
          <Form.Group className="d-flex">
            <Form.Label className="pe-4">ایا ملک لوکس است؟</Form.Label>
            <Form.Label className="me-2">خیر</Form.Label>
            <Form.Check
              type="switch"
              defaultChecked={lux}
              onChange={() => setLux(!lux)}
              style={{ fontSize: '1.3rem' }}
              isValid
            />
            <Form.Label>بله</Form.Label>
          </Form.Group>
          <h4 className="mt-4 mb-3">اجاره</h4>
          <Form.Group className="d-flex">
            <Form.Label className="pe-4">ایا ملک اجاره ای است؟</Form.Label>
            <Form.Label className="me-2">خیر</Form.Label>
            <Form.Check
              type="switch"
              defaultChecked={activeRent}
              onChange={() => setActiveRent(!activeRent)}
              style={{ fontSize: '1.3rem' }}
              isValid
            />
            <Form.Label>بله</Form.Label>
          </Form.Group>
          {activeRent && (
            <>
              <Form.Group className="pt-2" controlId="pish">
                <Form.Label>پول پیش</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder=" مقدار پول پیش را وارد کنید"
                  style={{ boxShadow: 'none', direction: 'rtl' }}
                  onChange={(e) => setRentPish(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="ejare" className="pt-3">
                <Form.Label>مقدار اجاره</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="مقدار اجاره ماهیانه را وارد کنید"
                  style={{ boxShadow: 'none', direction: 'rtl' }}
                  onChange={(e) => setRentMonth(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </>
          )}
          {!activeRent && (
            <>
              <h4 className="mt-4 mb-3">خرید</h4>
              <Form.Group controlId="pricepermeter" className="pt-3">
                <Form.Label>قیمت هر متر</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="مقدار قیمت هر متر از ملک را وارد کنید (فقط عدد)"
                  style={{ boxShadow: 'none', direction: 'rtl' }}
                  onChange={(e) => {
                    setPricePerMeter(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="totalprice" className="pt-3">
                <Form.Label>
                  قیمت کل
                  {meter > 0 && pricePerMeter > 0 && (
                    <span className="ps-1 text-muted">
                      ({numberSeparator(pricePerMeter * meter, ',')})
                    </span>
                  )}
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  style={{ boxShadow: 'none', direction: 'rtl' }}
                  placeholder="قیمت کل ملک را وارد کنید"
                  onChange={(e) => setTotalPrice(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </>
          )}
          <h4 className="mt-4">بارگذاری تصاویر</h4>
          <Form.Group controlId="images" className="mt-3">
            <Form.Label>تصویر 1</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images2" className="mt-3">
            <Form.Label>تصویر 2</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler2}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images3" className="mt-3">
            <Form.Label>تصویر 3</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler3}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images4" className="mt-3">
            <Form.Label>تصویر 4</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler4}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images5" className="mt-3">
            <Form.Label>تصویر 5</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler5}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images6" className="mt-3">
            <Form.Label>تصویر 6</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler6}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images7" className="mt-3">
            <Form.Label>تصویر 7</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler7}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <h4 className="my-4 muted">(اختیاری)</h4>
          <Form.Group controlId="images8" className="mt-3">
            <Form.Label>تصویر 8</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler8}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images9" className="mt-3">
            <Form.Label>تصویر 9</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler9}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images10" className="mt-3">
            <Form.Label>تصویر 10</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler10}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images11" className="mt-3">
            <Form.Label>تصویر 11</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler11}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images12" className="mt-3">
            <Form.Label>تصویر 12</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler12}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images13" className="mt-3">
            <Form.Label>تصویر 13</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler13}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images14" className="mt-3">
            <Form.Label>تصویر 14</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler14}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="images15" className="mt-3">
            <Form.Label>تصویر 15</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب عکس"
              className="mt-2"
              onChange={uploadHandler15}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>
          <Form.Group controlId="video" className="mt-3">
            <Form.Label>ویدیو</Form.Label>
            <Form.Control
              type="file"
              label="انتخاب ویدیو"
              className="mt-2"
              onChange={videoHandler}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>

          <h4 className="my-4">امکانات</h4>
          <Row className="m-0">
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">انتن مرکزی</Form.Label>
                <Form.Check
                  defaultChecked={anten}
                  onChange={() => setAnten(!anten)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">اسانسور</Form.Label>
                <Form.Check
                  defaultChecked={asansor}
                  onChange={() => setAsansor(!asansor)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">درب ریموت</Form.Label>
                <Form.Check
                  defaultChecked={remoteDoor}
                  onChange={() => setRemoteDoor(!remoteDoor)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">کولر آبی</Form.Label>
                <Form.Check
                  defaultChecked={cooler}
                  onChange={() => setCooler(!cooler)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">کولر گازی</Form.Label>
                <Form.Check
                  defaultChecked={spilet}
                  onChange={() => setSpilet(!spilet)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">انباری</Form.Label>
                <Form.Check
                  defaultChecked={anbari}
                  onChange={() => setAnbari(!anbari)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">بالکن</Form.Label>
                <Form.Check
                  defaultChecked={balkon}
                  onChange={() => setBalkon(!balkon)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">ایفون تصویری</Form.Label>
                <Form.Check
                  defaultChecked={iphone}
                  onChange={() => setIphone(!iphone)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">پکیج</Form.Label>
                <Form.Check
                  defaultChecked={packagee}
                  onChange={() => setPackagee(!packagee)}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">آب گرم کن</Form.Label>
                <Form.Check
                  defaultChecked={abgarm}
                  onChange={() => setAbgarm(!abgarm)}
                />
              </Form.Group>
            </Col>
          </Row>
          <h4 className="my-4">طبقه بندی ملک</h4>
          <Alert variant="info" className="mb-4">
            موارد زیر برای طبقه بندی ملک ها استفاده شده و در صفحه ملک به کاربر
            نمایش داده نمی شود. هر کدام از گزینه ها فعال باشد ملک در ان طبقه
            بندی قرار می گیرد.
          </Alert>
          <Row className="mb-4">
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">پروژه ساخت و ساز</Form.Label>
                <Form.Check
                  defaultChecked={sakhtosaaz}
                  onChange={() => {
                    setSakhtosaaz(!sakhtosaaz);
                    changeCattegory('sakhtosaaz');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">فروش اداری و تجاری</Form.Label>
                <Form.Check
                  defaultChecked={sellEdari}
                  onChange={() => {
                    setSellEdari(!sellEdari);
                    changeCattegory('sellEdari');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">اجاره مسکونی</Form.Label>
                <Form.Check
                  defaultChecked={rentHome}
                  onChange={() => {
                    setRentHome(!rentHome);
                    changeCattegory('rentHome');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">اجاره اداری و تجاری</Form.Label>
                <Form.Check
                  defaultChecked={rentEdari}
                  onChange={() => {
                    setRentEdari(!rentEdari);
                    changeCattegory('rentEdari');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">اجاره کوتاه مدت</Form.Label>
                <Form.Check
                  defaultChecked={shortRent}
                  onChange={() => {
                    setShortRent(!shortRent);
                    changeCattegory('shortRent');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">فروش مسکونی</Form.Label>
                <Form.Check
                  defaultChecked={sellHome}
                  onChange={() => {
                    setSellHome(!sellHome);
                    changeCattegory('sellHome');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">فروش متفرقه</Form.Label>
                <Form.Check
                  defaultChecked={village}
                  onChange={() => {
                    setVillage(!village);
                    changeCattegory('village');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">
                  فروش باغ و زمین صنعتی و ..
                </Form.Label>
                <Form.Check
                  defaultChecked={sellGarden}
                  onChange={() => {
                    setSellGarden(!sellGarden);
                    changeCattegory('sellGarden');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">معاوضه</Form.Label>
                <Form.Check
                  defaultChecked={exChanage}
                  onChange={() => {
                    setExChanage(!exChanage);
                    changeCattegory('exChanage');
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* *****************############## *****************##############
           *****************############## *****************##############
           *****************############## *****************##############
           *****************############## *****************##############
           *****************############## *****************##############
           *****************############## *****************############## */}
          <Row className="mx-0 mb-5">
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">مشارکت در ساخت و ساز</Form.Label>
                <Form.Check
                  defaultChecked={sakhtosaazMosharekat}
                  onChange={() => {
                    setSakhtosaazMosharekat(!sakhtosaazMosharekat);
                    changeCattegory('sakhtosaazMosharekat');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">پیش فروش</Form.Label>
                <Form.Check
                  defaultChecked={preSell}
                  onChange={() => {
                    setPreSell(!preSell);
                    changeCattegory('preSell');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">متفرقه</Form.Label>
                <Form.Check
                  defaultChecked={others}
                  onChange={() => {
                    setOthers(!others);
                    changeCattegory('others');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">
                  دفتر کار اتاق اداری و مطب
                </Form.Label>
                <Form.Check
                  defaultChecked={office}
                  onChange={() => {
                    setOffice(!office);
                    changeCattegory('office');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">مغازه و قرفه</Form.Label>
                <Form.Check
                  defaultChecked={shop}
                  onChange={() => {
                    setShop(!shop);
                    changeCattegory('shop');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">اپارتمان</Form.Label>
                <Form.Check
                  defaultChecked={apartment}
                  onChange={() => {
                    setApartment(!apartment);
                    changeCattegory('apartment');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">ویلایی</Form.Label>
                <Form.Check
                  defaultChecked={vilaaii}
                  onChange={() => {
                    setVilaaii(!vilaaii);
                    changeCattegory('vilaaii');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">زمین</Form.Label>
                <Form.Check
                  defaultChecked={land}
                  onChange={() => {
                    setLand(!land);
                    changeCattegory('land');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">ویلا و باغ</Form.Label>
                <Form.Check
                  defaultChecked={vilaavBaagh}
                  onChange={() => {
                    setVilaavBaagh(!vilaavBaagh);
                    changeCattegory('vilaavBaagh');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">سوله</Form.Label>
                <Form.Check
                  defaultChecked={sule}
                  onChange={() => {
                    setSule(!sule);
                    changeCattegory('sule');
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">باغ ویلا</Form.Label>
                <Form.Check
                  defaultChecked={baaghVilla}
                  onChange={() => {
                    setBaaghVilla(!baaghVilla);
                    changeCattegory('baaghVilla');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">زمین صنعتی و کشاورزی</Form.Label>
                <Form.Check
                  defaultChecked={sanatiKeshavarzi}
                  onChange={() => {
                    setSanatiKeshavarzi(!sanatiKeshavarzi);
                    changeCattegory('sanatiKeshavarzi');
                  }}
                />
              </Form.Group>
              <Form.Group className="d-flex my-4">
                <Form.Label className="pe-2">زمین هکتاری</Form.Label>
                <Form.Check
                  defaultChecked={hektari}
                  onChange={() => {
                    setHektari(!hektari);
                    changeCattegory('hektari');
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="create-property-submit">
            <button type="submit" className="main-button">
              اضافه کردن
            </button>
          </Row>
          {loading && <Loader className="mt-4" />}
          {success && (
            <Alert variant="success" className="mt-4">
              ملک با موفقیت اضافه شد.
            </Alert>
          )}
          {error && (
            <Alert variant="danger" className="mt-4">
              {error}
            </Alert>
          )}
        </Form>
      </div>
    </Container>
  );
};

export default AddProperty;
