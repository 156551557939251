import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cattegories from '../components/Cattegories';
import ImageSwiper from '../components/ImageSwiper';
import { useSelector, useDispatch } from 'react-redux';
import { getLatestProperties } from '../redux/actions/propertyAction';
import { Alert, Col, Row } from 'react-bootstrap';
import PropertyCard from '../components/PropertyCard';
import Loader from '../components/Loader';
const MainScreen = () => {
  const dispatch = useDispatch();
  const latestProperties = useSelector((state) => state.getLatest);
  const { loading, error, properties } = latestProperties;
  useEffect(() => {
    dispatch(getLatestProperties());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <div>
          <ImageSwiper />
          <h2 className="my-5 text-center lux-cat">
            <Link to="/lux">دیدن ملک های لوکس و استخر دار</Link>
          </h2>
          <h2 className="my-5 ps-2">طبقه بندی املاک </h2>
          <Cattegories />
          <h2 className="my-5 ps-2">آخرین ملک ها</h2>

          <Row className="d-flex m-0">
            {properties?.length > 1 &&
              properties.map((property, index) => {
                return (
                  <Col
                    className="px-4 my-2"
                    key={index}
                    sm={12}
                    md={6}
                    lg={3}
                    xl={3}
                  >
                    <PropertyCard item={property} className="mx-3" />
                  </Col>
                );
              })}
          </Row>
        </div>
      )}
    </>
  );
};

export default MainScreen;
