import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProperties } from "../redux/actions/propertyAction";
import Loader from "../components/Loader";
import { Row, Col, Alert } from "react-bootstrap";
import PropertyCard from "../components/PropertyCard";
import Paginate from "../components/Paginate";
const PropertyList = ({ location }) => {
  const dispatch = useDispatch();
  const query = location.search.split("&");
  const keyword = query[0].split("=")[1];
  const page = query[1] ? query[1].split("=")[1] : 1;
  const getPropertiesList = useSelector((state) => state.getProperties);
  const { properties, loading, error, pages } = getPropertiesList;
  useEffect(() => {
    dispatch(getProperties(keyword, page));
  }, [dispatch, keyword, page]);
  return (
    <>
      {error ? (
        <Alert variant="danger" className="mt-5">
          {error}
        </Alert>
      ) : (
        <>
          <h2 className="mt-4 mb-5 mx-3 mt-md-5">
            نتایج جستجو برای ( {keyword.split("&")[0]} )
          </h2>
          {loading ? (
            <Loader />
          ) : (
            <Row className="property-list">
              {properties?.length > 0 ? (
                properties?.map((propertie, index) => (
                  <Col
                    key={index}
                    sm={12}
                    md={6}
                    lg={3}
                    xl={3}
                    className="rounded mb-4 property-item"
                  >
                    <PropertyCard item={propertie} />
                  </Col>
                ))
              ) : (
                <Alert variant="info"> هیچ ملکی پیدا نشد.</Alert>
              )}
            </Row>
          )}
        </>
      )}
      <Paginate page={Number(page)} pages={pages} keyword={keyword} />
    </>
  );
};

export default PropertyList;
