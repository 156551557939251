import React, { useState, useEffect } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../redux/actions/userAction';
import Loader from '../components/Loader';
const LoginScreen = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  useEffect(() => {
    if (userInfo) {
      history.push('/');
    }
  }, [userInfo, history]);
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 pt-5 ">
      <Form onSubmit={submitHandler}>
        <h4>وارد شوید</h4>
        <Form.Group
          controlId="parking"
          className="pt-3"
          style={{ width: '60vw' }}
        >
          <Form.Label>ایمیل</Form.Label>
          <Form.Control
            type="email"
            style={{ boxShadow: 'none' }}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group
          controlId="parking"
          className="pt-3 pb-5"
          style={{ width: '60vw' }}
        >
          <Form.Label>کلمه عبور</Form.Label>
          <Form.Control
            type="password"
            style={{ boxShadow: 'none' }}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <button type="submit" className="main-button">
          وارد شوید
        </button>
        {loading && <Loader />}
        {error && <Alert variant="danger mt-4">{error}</Alert>}
      </Form>
    </div>
  );
};

export default LoginScreen;
