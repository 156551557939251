import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { FaAddressCard, FaInstagram, FaMobile, FaPhone } from 'react-icons/fa';
const Footer = () => {
  return (
    <>
      <div
        className="bg-white entire-footer border-top"
        style={{ overflow: 'hidden', marginTop: '10rem' }}
        id="footer"
      >
        <h1 className="ps-5 pt-5">ارتباط با ما</h1>
        <Col className="mx-2 mx-md-5 pt-4 footer-about">
          <div className="footer-about-col">
            <span className="h4 mb-1 mb-md-4 mt-3">آدرس</span>
            <span>
              اندیشه فاز یک خیابان ارغوان پنجم پلاک ۵/۳ <FaAddressCard />
            </span>
          </div>
          <div className="footer-about-col">
            <span className="h4 mb-1 mb-md-4 mt-3">شماره تلفن</span>

            <span>
              <a href="tel:02165579106" className="pe-2">
                65579106
              </a>
              <FaPhone />
            </span>
            <span>
              <a href="tel:02165579125" className="pe-2">
                65579125
              </a>
              <FaPhone />
            </span>
          </div>
          <div className="footer-about-col">
            <span className="h4 mb-1 mb-md-4 mt-3">شماره موبایل</span>
            <span>
              <a href="tel:09194508707">09194508707</a>
              <FaMobile />
            </span>
          </div>

          <div className="footer-about-col">
            <span className="h4 mb-1 mb-md-4 mt-3">اینستاگرام</span>
            <span>
              <a href="https://instagram.com/vision_melk">vision_melk </a>
              <FaInstagram />
            </span>
          </div>
        </Col>
        <footer className="d-flex" style={{ height: '28vh' }}>
          <Container style={{ alignSelf: 'end' }}>
            <Row>
              <Col className="text-center py-3">
                <h6 className="text-dark">
                  <a
                    href="https://theradiocode.com"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    RadioCode 2022 © || Design & Deployed by itsmohama_d
                  </a>
                </h6>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
};

export default Footer;
