import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Image } from "react-bootstrap";

const ImageSwiper = () => {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="myswiper"
        style={{ height: "70vh" }}
      >
        <SwiperSlide>
          <Image src="./images/1.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/2.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/3.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/4.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/5.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/6.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src="./images/7.jpg" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ImageSwiper;
