export const PROPERTY_CREATE_REQUEST = 'PROPERTY_CREATE_REQUEST';
export const PROPERTY_CREATE_SUCCESS = 'PROPERTY_CREATE_SUCCESS';
export const PROPERTY_CREATE_FAIL = 'PROPERTY_CREATE_FAIL';
export const PROPERTY_CREATE_RESET = 'PROPERTY_CREATE_RESET';
export const GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST';
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_FAIL = 'GET_PROPERTY_FAIL';
export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST';
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS';
export const GET_PROPERTY_FAIL = 'GET_PROPERTY_FAIL';
export const GET_PROPERTY_RESET = 'GET_PROPERTY_RESET';
export const DELETE_PROPERTY_REQUEST = 'DELETE_PROPERTY_REQUEST';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAIL = 'DELETE_PROPERTY_FAIL';
export const DELETE_PROPERTY_RESET = 'DELETE_PROPERTY_RESET';
export const UPDATE_PROPERTY_REQUEST = 'UPDATE_PROPERTY_REQUEST';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAIL = 'UPDATE_PROPERTY_FAIL';
export const UPDATE_PROPERTY_RESET = 'UPDATE_PROPERTY_RESET';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';
export const GET_LUX_REQUEST = 'GET_LUX_REQUEST';
export const GET_LUX_SUCCESS = 'GET_LUX_SUCCESS';
export const GET_LUX_FAIL = 'GET_LUX_FAIL';
export const GET_LATEST_REQUEST = 'GET_LATEST_REQUEST';
export const GET_LATEST_SUCCESS = 'GET_LATEST_SUCCESS';
export const GET_LATEST_FAIL = 'GET_LATEST_FAIL';
