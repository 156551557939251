import React from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const PropertyCard = ({ item }) => {
  const history = useHistory();

  return (
    <>
      <Card>
        <Card.Img variant="top" src={item?.image} style={{ height: '13rem' }} />
        <Card.Body className="p-2 py-3">
          <Card.Title>{item?.name}</Card.Title>

          <button
            className="main-button mt-3"
            onClick={() => history.push(`/property/${item?._id}`)}
          >
            دیدن ملک
          </button>
        </Card.Body>
      </Card>
    </>
  );
};

export default PropertyCard;
