import {
  PROPERTY_CREATE_REQUEST,
  PROPERTY_CREATE_SUCCESS,
  PROPERTY_CREATE_FAIL,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_FAIL,
  GET_PROPERTIES_REQUEST,
  GET_PROPERTY_FAIL,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_REQUEST,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  PROPERTY_CREATE_RESET,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  DELETE_PROPERTY_RESET,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  UPDATE_PROPERTY_RESET,
  GET_LUX_REQUEST,
  GET_LUX_SUCCESS,
  GET_LUX_FAIL,
  GET_LATEST_REQUEST,
  GET_LATEST_SUCCESS,
  GET_LATEST_FAIL,
  GET_PROPERTY_RESET,
} from '../constants/propertConstant';

export const createPropertyReducer = (state = {}, action) => {
  switch (action.type) {
    case PROPERTY_CREATE_REQUEST:
      return { loading: true, success: false };
    case PROPERTY_CREATE_SUCCESS:
      return { loading: false, propertyInfo: action.payload, success: true };
    case PROPERTY_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case PROPERTY_CREATE_RESET:
      return { success: false };
    default:
      return state;
  }
};
export const updatePropertyReducer = (state = { property: {} }, action) => {
  switch (action.type) {
    case UPDATE_PROPERTY_REQUEST:
      return { loading: true };
    case UPDATE_PROPERTY_SUCCESS:
      return { loading: false, property: action.payload, success: true };
    case UPDATE_PROPERTY_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_PROPERTY_RESET:
      return { property: {} };
    default:
      return state;
  }
};

export const getCategoryReducer = (state = { properties: [] }, action) => {
  switch (action.type) {
    case GET_CATEGORY_REQUEST:
      return { loading: true, properties: [] };
    case GET_CATEGORY_SUCCESS:
      return {
        loading: false,
        properties: action.payload.properties,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case GET_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getLuxReducer = (state = { properties: [] }, action) => {
  switch (action.type) {
    case GET_LUX_REQUEST:
      return { loading: true, properties: [] };
    case GET_LUX_SUCCESS:
      return {
        loading: false,
        properties: action.payload.properties,
      };
    case GET_LUX_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getPropertiesReducer = (state = { properties: [] }, action) => {
  switch (action.type) {
    case GET_PROPERTIES_REQUEST:
      return { loading: true, properties: [] };
    case GET_PROPERTIES_SUCCESS:
      return {
        loading: false,
        properties: action.payload.properties,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case GET_PROPERTIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPropertyReducer = (state = { property: {} }, action) => {
  switch (action.type) {
    case GET_PROPERTY_REQUEST:
      return { loading: true };
    case GET_PROPERTY_SUCCESS:
      return { loading: false, property: action.payload, success: true };
    case GET_PROPERTY_FAIL:
      return { loading: false, error: action.payload };
    case GET_PROPERTY_RESET:
      return { success: false };
    default:
      return state;
  }
};
export const getLatestReducer = (state = { properties: {} }, action) => {
  switch (action.type) {
    case GET_LATEST_REQUEST:
      return { loading: true };
    case GET_LATEST_SUCCESS:
      return { loading: false, properties: action.payload };
    case GET_LATEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const deletePropertyReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROPERTY_REQUEST:
      return { loading: true };
    case DELETE_PROPERTY_SUCCESS:
      return { loading: false, success: true };
    case DELETE_PROPERTY_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_PROPERTY_RESET:
      return { success: false };
    default:
      return state;
  }
};
