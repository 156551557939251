import React from "react";
import { FaPhone, FaAddressCard, FaMobile, FaInstagram } from "react-icons/fa";
const ContactUs = () => {
  return (
    <div>
      <h2 className="my-4 ps-3">راه های ارتباطی</h2>
      <div className="ms-5">
        <div>
          <h4 className="py-3">آدرس: </h4>
          <h5>
            اندیشه فاز یک خیابان ارغوان پنجم پلاک ۵/۳ <FaAddressCard />
          </h5>
        </div>
        <div>
          <h4 className="py-3">شماره تلفن: </h4>
          <h5>
            65579106 & 65579125 <FaPhone />
          </h5>
        </div>
        <div>
          <h4 className="py-3">شماره موبایل: </h4>
          <h5>
            09194508707 <FaMobile />
          </h5>
        </div>
        <div>
          <h4 className="py-3">اینستاگرام: </h4>
          <h5>
            vision_melk <FaInstagram />
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
