import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css';
import { BrowserRouter, Route } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import PropertyScreen from './screens/PropertyScreen';
import SearchBar from './components/SearchBar';
import LogoBanner from './components/LogoBanner';
import AddProperty from './screens/AddProperty';
import LoginScreen from './screens/LoginScreen';
import Footer from './components/Footer';
import PropertyList from './screens/PropertyList';
import CategoryList from './screens/CategoryList';
import ContactUs from './screens/ContactUs';
import EditProperty from './screens/EditProperty';
import LuxScreen from './screens/LuxScreen';
import NumberCall from './components/NumberCall';
import AboutUs from './screens/AboutUs';
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <LogoBanner />
        <SearchBar />
        <NumberCall />
        <Route exact component={MainScreen} path="/" />
        <Route component={EditProperty} path="/property/:id/edit" />
        <Route component={PropertyScreen} path="/property/:id" exact />
        <Route component={PropertyList} path="/properties" />
        <Route component={LuxScreen} path="/lux" />
        <Route component={CategoryList} path="/categories" />
        <Route component={AddProperty} path="/admin/addproperty" />
        <Route component={LoginScreen} path="/admin/login" />
        <Route component={ContactUs} path="/contactus" />
        <Route component={AboutUs} path="/aboutus" />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
