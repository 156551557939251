import { combineReducers } from 'redux';
import {
  createPropertyReducer,
  getPropertiesReducer,
  getPropertyReducer,
  deletePropertyReducer,
  getCategoryReducer,
  updatePropertyReducer,
  getLuxReducer,
  getLatestReducer,
} from './propertyReducers';
import { userLoginReducer } from './userReducers';

const reducers = combineReducers({
  userLogin: userLoginReducer,
  getCategory: getCategoryReducer,
  createProperty: createPropertyReducer,
  getProperties: getPropertiesReducer,
  getProperty: getPropertyReducer,
  deleteProperty: deletePropertyReducer,
  updateProperty: updatePropertyReducer,
  luxReducer: getLuxReducer,
  getLatest: getLatestReducer,
});
export default reducers;
