import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const SearchBar = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const submitHandler = (e) => {
    e.preventDefault();
    history.push(`/properties?keyword=${search}`);
  };
  const changeHandler = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="navbar-container">
      <form className="navbar-search pe-1" onSubmit={submitHandler}>
        <input
          type="text"
          placeholder="کد ملک را وارد کنید"
          onChange={changeHandler}
        ></input>
        <button className="main-button-out ms-2" type="submit">
          جستجو
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
