import React, { useEffect } from 'react';
import { Button, Col, Row, ListGroup, Alert } from 'react-bootstrap';
import PropertySwiper from '../components/PropertySwiper';
import { FaCheck, FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import numberSeparator from 'number-separator';
import { useSelector, useDispatch } from 'react-redux';
import { deleteProperty, getProperty } from '../redux/actions/propertyAction';
import Loader from '../components/Loader';
import entopr from '../scripts/entopr';
import { Link } from 'react-router-dom';
import { DELETE_PROPERTY_RESET } from '../redux/constants/propertConstant';
import ContactBox from '../components/ContactBox';
const PropertyScreen = ({ match, history }) => {
  const id = match.params.id;
  const dispatch = useDispatch();
  const getPropertyinfo = useSelector((state) => state.getProperty);
  const { property, error, loading } = getPropertyinfo;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading: loadingUser } = userLogin;
  const propertyDelete = useSelector((state) => state.deleteProperty);
  const { success, loading: loadingProperty } = propertyDelete;
  useEffect(() => {
    if (success) {
      history.push('/');
      dispatch({ type: DELETE_PROPERTY_RESET });
    }
    dispatch(getProperty(id));
  }, [dispatch, success, id, history]);
  const deleteHandler = () => {
    if (window.confirm('ایا از حذف ملک مطمئن هستید؟'))
      dispatch(deleteProperty(id));
  };
  return (
    <>
      {error ? (
        <Alert variant="danger" className="mt-5 ms-5" style={{ width: '50%' }}>
          {error}
        </Alert>
      ) : (
        <div>
          {loading && <Loader />}
          <Row className="mx-0 my-0 my-md-5 ">
            <Col className="px-0" md={{ order: '2' }}>
              <PropertySwiper
                image={property?.image}
                image2={property?.image2}
                image3={property?.image3}
                image4={property?.image4}
                image5={property?.image5}
                image6={property?.image6}
                image7={property?.image7}
                image8={property?.image8}
                image9={property?.image9}
                image10={property?.image10}
                image11={property?.image11}
                image12={property?.image12}
                image13={property?.image13}
                image14={property?.image14}
                image15={property?.image15}
                video={property?.video}
              />
            </Col>
            <Col className="pe-0 mt-4 ms-0 ms-md-5">
              <Row className="mb-1 mx-0">
                <h4>{property?.name}</h4>
              </Row>
              <Row className="mb-4 mx-0">
                <h6 className="text-muted"> کد ملک : ({property?.code})</h6>
              </Row>
              <div className="property-button pb-3">
                <a href="#footer">
                  <button className="main-button">ارتباط با ما</button>
                </a>
                {loadingProperty || loadingUser ? (
                  <Loader />
                ) : (
                  userInfo && (
                    <>
                      <Button
                        className="ms-3"
                        variant="danger"
                        onClick={deleteHandler}
                      >
                        <FaTrash className="pe-1" />
                        حذف
                      </Button>
                      <Link to={`/property/${id}/edit`}>
                        <button className="ms-3 main-button-outline">
                          <FaEdit className="pe-1" />
                          ویرایش
                        </button>
                      </Link>
                    </>
                  )
                )}
              </div>
              <div className="mx-5 mt-4 d-flex text-center">
                <Col className="border-end">
                  <span style={{ fontWeight: 'bold' }}>متراژ</span>
                  <p className="pt-2" style={{ fontWeight: '300' }}>
                    {entopr(property?.meter)}
                  </p>
                </Col>
                <Col className="border-end">
                  <span style={{ fontWeight: 'bold' }}>ساخت</span>
                  <p className="pt-2" style={{ fontWeight: '300' }}>
                    {entopr(property?.year)}
                  </p>
                </Col>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>وضعیت</span>
                  <p className="pt-2" style={{ fontWeight: '300' }}>
                    {property?.rent ? <span>اجاره</span> : <span>خرید</span>}
                  </p>
                </Col>
              </div>
              <div>
                <ListGroup variant="flush" className="me-5 pe-5 mt-4">
                  <ListGroup.Item className="py-3">
                    {property?.rent ? (
                      <span>
                        پول پیش :
                        {numberSeparator(entopr(property?.rentPish), ',')} تومان
                      </span>
                    ) : (
                      <span>
                        قیمت هر متر :
                        {numberSeparator(entopr(property?.pricePerMeter), ',')}
                        تومان
                      </span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    {property?.rent ? (
                      <span>
                        اجاره ماهیانه:
                        {numberSeparator(entopr(property?.rentMonth), ',')}
                        تومان
                      </span>
                    ) : (
                      <span>
                        قیمت کل:
                        {numberSeparator(entopr(property?.totalPrice), ',')}
                        تومان
                      </span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <span>نما:</span>
                    <span> {property?.nama} </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <span>پارکینگ: </span>
                    <span> {property?.parking} </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="py-3">
                    <span>طبقه: </span>
                    <span> {property?.floor} </span>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              {property?.bed && (
                <div className="mt-3">
                  <h4 style={{ fontWeight: 'bold' }}>تعداد اتاق خواب</h4>
                  <Row className="m-0 my-5">
                    <Col className="pe-0">
                      <span className="pe-2">دوخواب</span>
                      {property?.bed === 2 ? (
                        <FaCheck className="text-success" />
                      ) : (
                        <FaTimes className="text-danger" />
                      )}
                    </Col>
                    <Col className="pe-0">
                      <span className="pe-2">یک خواب</span>
                      {property?.bed === 1 ? (
                        <FaCheck className="text-success" />
                      ) : (
                        <FaTimes className="text-danger" />
                      )}
                    </Col>
                    <Col className="pe-0">
                      <span className="pe-2">سایر</span>
                      {property?.bed === 0 ? (
                        <FaCheck className="text-success" />
                      ) : (
                        <FaTimes className="text-danger" />
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              <div className="mt-3">
                <h4 style={{ fontWeight: 'bold' }}>امکانات</h4>
                <Row className="pb-4 pt-4 me-0">
                  <Col className="pe-0">
                    <span className="pe-2">اسانسور</span>
                    {property?.asansor ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="pe-0">
                    <span className="pe-2">بالکن</span>
                    {property?.balkon ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="pe-0">
                    <span className="pe-2">درب ریموت</span>
                    {property?.remoteDoor ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                </Row>
                <Row className="pb-4 me-0">
                  <Col className="pe-0">
                    <span className="pe-2">انتن مرکزی</span>
                    {property?.anten ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="pe-0">
                    <span className="pe-2">پکیج</span>
                    {property?.packagee ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="pe-0">
                    <span className="pe-2">انباری</span>
                    {property?.anbari ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                </Row>
                <Row className="m-0 pb-4">
                  <Col className="ps-0">
                    <span className="pe-2">ایفون تصویری</span>
                    {property?.iphone ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="ps-0">
                    <span className="pe-2">کولر گازی</span>
                    {property?.spilet ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                  <Col className="ps-0">
                    <span className="pe-2">کولرآبی</span>
                    {property?.cooler ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col className="ps-0">
                    <span className="pe-2">آب گرم کن</span>
                    {property?.abgarm ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mt-5">
                <h4 style={{ fontWeight: 'bold' }} className="mb-3">
                  توضیحات
                </h4>
                <p className="px-3">{property?.description}</p>
              </div>
              <ContactBox />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PropertyScreen;
