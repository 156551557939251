import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaUser } from 'react-icons/fa';

const LogoBanner = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <div className="logo-banner-container">
      <Col className="text-white">
        <Link to="/" className="linksemplified">
          <span>مسکن ویژن</span>
        </Link>
      </Col>
      <Col className="logo-banner-button-container">
        <a href="#footer">
          <button className="main-button">ارتباط با ما</button>
        </a>
        {userInfo ? (
          <Link to="/admin/addproperty">
            <button className="main-button ms-2">
              <FaUser /> مدیر
            </button>
          </Link>
        ) : (
          <Link to="/aboutus">
            <button className="main-button ms-2">درباره ما</button>
          </Link>
        )}
      </Col>
    </div>
  );
};

export default LogoBanner;
