import {
  PROPERTY_CREATE_FAIL,
  PROPERTY_CREATE_REQUEST,
  PROPERTY_CREATE_SUCCESS,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_FAIL,
  GET_PROPERTIES_REQUEST,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_FAIL,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  GET_LUX_REQUEST,
  GET_LUX_SUCCESS,
  GET_LUX_FAIL,
  GET_LATEST_REQUEST,
  GET_LATEST_SUCCESS,
  GET_LATEST_FAIL,
} from '../constants/propertConstant';
import axios from 'axios';
export const createProperty = (property) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROPERTY_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Contant-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      '/api/properties',
      {
        ...property,
      },
      config
    );
    dispatch({
      type: PROPERTY_CREATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: PROPERTY_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const updateProperty = (property, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PROPERTY_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Contant-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/properties/${id}`,
      {
        ...property,
      },
      config
    );
    dispatch({
      type: UPDATE_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PROPERTY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getProperties =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PROPERTIES_REQUEST });

      const { data } = await axios.get(
        `/api/properties?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({ type: GET_PROPERTIES_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: GET_PROPERTIES_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };
export const getLatestProperties = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LATEST_REQUEST });

    const { data } = await axios.get('/api/properties/latest');

    dispatch({ type: GET_LATEST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_LATEST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const getLuxProperties = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LUX_REQUEST });

    const { data } = await axios.get(`/api/properties/lux`);

    dispatch({ type: GET_LUX_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_LUX_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const getProperty = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROPERTY_REQUEST });

    const { data } = await axios.get(`/api/properties/${id}`);

    dispatch({ type: GET_PROPERTY_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_PROPERTY_FAIL,
      payload:
        err.response && err.response?.data?.message
          ? err.response?.data?.message
          : err?.message,
    });
  }
};
export const deleteProperty = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_PROPERTY_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/properties/${id}`, config);
    dispatch({
      type: DELETE_PROPERTY_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: DELETE_PROPERTY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const getPropertiesByCategory =
  (keyword = [], pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CATEGORY_REQUEST });

      const { data } = await axios.get(
        `/api/properties/category?keyword=${keyword[0]}-${keyword[1]}&pageNumber=${pageNumber}`
      );

      dispatch({ type: GET_CATEGORY_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: GET_CATEGORY_FAIL,

        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };
