import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({ pages, page, keyword = "", isCategory = false }) => {
  return (
    pages > 1 && (
      <Pagination className="mt-5 ms-5 mb-0">
        {[...Array(pages)?.keys()]?.map((x) => (
          <LinkContainer
            key={x + 1}
            to={
              isCategory
                ? `/categories?keyword=${keyword[0]}-${keyword[1]}&page=${
                    x + 1
                  }`
                : `/properties?keyword=${keyword}&page=${x + 1}`
            }
          >
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  );
};

export default Paginate;
