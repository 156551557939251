import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loader from "../components/Loader"
import { Row, Col, Alert } from "react-bootstrap"
import PropertyCard from "../components/PropertyCard"
import { getLuxProperties } from "../redux/actions/propertyAction"
const LuxScreen = ({ location }) => {
  const dispatch = useDispatch()
  const luxProperties = useSelector((state) => state.luxReducer)
  const { loading, error, properties } = luxProperties
  useEffect(() => {
    dispatch(getLuxProperties())
  }, [dispatch])
  return (
    <>
      {error ? (
        <Alert>{error}</Alert>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <h2 className="mt-4 mb-5 mx-3 mt-md-5">
                نتایج جستجو برای ( املاک لوکس )
              </h2>

              <Row className="property-list">
                {properties?.length < 1 ? (
                  <Alert variant="info">هیج ملکی وجود ندارد</Alert>
                ) : (
                  properties?.map((propertie, index) => (
                    <Col
                      key={index}
                      sm={12}
                      md={6}
                      lg={3}
                      xl={3}
                      className="rounded mb-4 property-item"
                    >
                      <PropertyCard item={propertie} />
                    </Col>
                  ))
                )}
              </Row>
            </>
          )}
        </>
      )}
    </>
  )
}

export default LuxScreen
