import React from 'react';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h1 className="ps-4 my-5">درباره املاک ویژن</h1>
      <p className="px-4 px-md-5">
        مسکن ویژِن دارای مجوز رسمی از اتحادیه صنف مشاورین املاک شهرستان شهریار
        با هدف خدمتی صادقانه و منصفانه با کادری مجرب و کاردان در حوزه معاملات
        املاک غرب استان تهران در راستای خرید و فروش و همچنین رهن و اجاره املاک
        مسکونی،تجاری و صنعتی و تخصص در خرید و فروش باغ ویلا های لوکس آماده خدمت
        رسانی به مشتریان خود می باشد. امروزه افراد بسیار زیادی هستند که ملک برای
        عرضه دارند و یا اینکه بدنبال ملک می گردند. یک وبسایت آگهی می تواند به
        راحتی این افراد را به یکدیگر برساند. سایت مسکن ویژِن تنها یک سایت خرید و
        فروش ملک و مستغلات نیست،بلکه یک بستر کامل برای رفع نیاز هر چه بهتر و
        آسانتر شما مشتریان عزیز میباشد.. مطمئنا شما هم مثل ما از ظاهر یکنواخت
        سایت های آگهی و نیازمندی موجود درایران خسته شده اید و به دنبال یک وبسایت
        حرفه ای تر با پشتیانی معتبر و قیمتی مناسب می گردید. جستجوی ملک مورد نظر
        در مسکن ویژِن در سایت مسکن ویژِن به آسانی می‌توانید منزل مورد نظرتان را
        بر اساس قیمت، متراژ و… دسته‌ بندی کنید و بهترین ها را بیابید. کافیست فقط
        یک بار خرید ملک و مستغلات را از سایت مسکن ویژِن امتحان کنید که متوجه
        صداقت در عمل کلیه مشاورین ما شوید.
      </p>
    </div>
  );
};

export default AboutUs;
