import React from 'react';
import { FaPhone } from 'react-icons/fa';

const ContactBox = () => {
  return (
    <div className="mx-3 mt-3 contact-box">
      <h5>
        برای اطلاع از قیمت به روز و مشخصات دقیق با شماره های زیر تماس بگیرید :
      </h5>
      <div className="contact-box-container">
        <div className="contact-box-right">
          <h6>کارشناس فروش :</h6>
          <span>
            <a href="tel:09194508707">عادل شاه نظری : ۰۹۱۹۴۵۰۸۷۰۷</a>
            <FaPhone className="ms-2" />
          </span>
        </div>
        <div className="contact-box-left">
          <h6>شماره های تماس دفتر :</h6>
          <div className="pt-1">
            <a href="tel:02165579125">۰۲۱۶۵۵۷۹۱۲۵</a>
            <FaPhone className="ms-2" />
          </div>
          <div className="pb-2">
            <a href="tel:02165579106">۰۲۱۶۵۵۷۹۱۰۶</a>
            <FaPhone className="ms-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBox;
