import React from 'react';
import { Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const PropertySwiper = ({
  image,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  video,
}) => {
  return (
    <>
      <Swiper
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="propertyswiper"
      >
        <SwiperSlide>
          <Image src={image} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image2} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image3} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image4} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image5} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image6} width="100%" height="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <Image src={image7} width="100%" height="100%" />
        </SwiperSlide>
        {image8 && (
          <SwiperSlide>
            <Image src={image8} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image9 && (
          <SwiperSlide>
            <Image src={image9} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image10 && (
          <SwiperSlide>
            <Image src={image10} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image11 && (
          <SwiperSlide>
            <Image src={image11} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image12 && (
          <SwiperSlide>
            <Image src={image12} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image13 && (
          <SwiperSlide>
            <Image src={image13} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image14 && (
          <SwiperSlide>
            <Image src={image14} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {image15 && (
          <SwiperSlide>
            <Image src={image15} width="100%" height="100%" />
          </SwiperSlide>
        )}
        {video && (
          <SwiperSlide>
            <video
              loop
              src={video}
              autoPlay
              muted
              loading="lazy"
              controls
              width="100%"
              height="100%"
            ></video>
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
};

export default PropertySwiper;
