import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Cattegories = () => {
  return (
    <div className="cattegories-container">
      <Row className="me-0">
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>پروژه ساخت و ساز</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=sakhtosaaz-sakhtosaazMosharekat">
                <ListGroup.Item className="cat-color">
                  مشارکت در ساخت
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sakhtosaaz-preSell">
                <ListGroup.Item className="cat-color">پیش فروش</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sakhtosaaz-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>فروش اداری و تجاری</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=sellEdari-office">
                <ListGroup.Item className="cat-color">
                  دفتر کار اتاق اداری و مطب
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellEdari-shop">
                <ListGroup.Item className="cat-color">
                  مغاره و غرفه
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellEdari-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>اجاره مسکونی</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=rentHome-apartment">
                <ListGroup.Item className="cat-color">اپارتمان</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=rentHome-vilaaii">
                <ListGroup.Item className="cat-color">ویلایی</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=rentHome-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row className="me-0">
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>اجاره اداری و تجاری</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=rentEdari-office">
                <ListGroup.Item className="cat-color">
                  دفتر کار اتاق اداری و مطب
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=rentEdari-shop">
                <ListGroup.Item className="cat-color">
                  مغازه و غرفه
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=rentEdari-sanatiKeshavarzi">
                <ListGroup.Item className="cat-color">
                  صنعتی کشاورزی و تجاری
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=rentEdari-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>اجاره کوتاه مدت</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=shortRent-apartment">
                <ListGroup.Item className="cat-color">
                  اپارتمان و سوییت
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=shortRent-vilaavBaagh">
                <ListGroup.Item className="cat-color">
                  ویلا و باغ
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=shortRent-office">
                <ListGroup.Item className="cat-color">
                  دفتر کار و فضای اموزشی
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=shortRent-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>فروش مسکونی</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=sellHome-apartment">
                <ListGroup.Item className="cat-color">اپارتمان</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellHome-vilaaii">
                <ListGroup.Item className="cat-color">ویلایی</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellHome-land">
                <ListGroup.Item className="cat-color">زمین</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellHome-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row className="me-0">
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>فروش متفرقه (مختص شهرستان ها)</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=village-apartment">
                <ListGroup.Item className="cat-color">اپارتمان</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=village-shop">
                <ListGroup.Item className="cat-color">تجاری</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=village-sule">
                <ListGroup.Item className="cat-color">سوله</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=village-baaghVilla">
                <ListGroup.Item className="cat-color">باغ ویلا</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>فروش باغ و باغچه و صنعتی و کشاورزی</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=sellGarden-vilaavBaagh">
                <ListGroup.Item className="cat-color">باغ</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellGarden-baaghVilla">
                <ListGroup.Item className="cat-color">باغ ویلا</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellGarden-vilaavBaagh">
                <ListGroup.Item className="cat-color">باغچه</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellGarden-sanatiKeshavarzi">
                <ListGroup.Item className="cat-color">
                  صنعتی و کشاورزی
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=sellGarden-hektari">
                <ListGroup.Item className="cat-color">
                  باغ و زمین های هکتاری
                </ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
        <Col className="mb-4">
          <Card
            style={{ width: '18rem' }}
            text="white"
            bg="dark"
            className="p-3"
          >
            <Card.Body>
              <Card.Title>معاوضه</Card.Title>
            </Card.Body>
            <ListGroup variant="flush">
              <Link to="/categories?keyword=exChanage-vilaavBaagh">
                <ListGroup.Item className="cat-color">باغ</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=exChanage-baaghVilla">
                <ListGroup.Item className="cat-color">باغ ویلا</ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=exChanage-sanatiKeshavarzi">
                <ListGroup.Item className="cat-color">
                  زمین های صنعتی وکشاورزی
                </ListGroup.Item>
              </Link>
              <Link to="/categories?keyword=exChanage-others">
                <ListGroup.Item className="cat-color">متفرقه</ListGroup.Item>
              </Link>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Cattegories;
